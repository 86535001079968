import HelloWorld from './HelloWorld';

const blogLinkList = [
  {
    component: HelloWorld,
    key: 'HelloWorld',
    path: '/HelloWorld',
    title: 'Hello World',
    date: 'Saturday March 16 2024',
    description: 'My First Blog Post!',
  },
];

export default blogLinkList;
